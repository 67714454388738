.b-advantage {
  background: url('../img/ic1.png') top center no-repeat;
  background-size: 80px;
  font-family: $font-family-avant-garderctt;
  font-size: 20px;
  font-weight: 400;
  padding-top: 99px;

  &_type {
    &_summ-garantee {
      background-image: url('../img/ic2.png');
    }

    &_percents {
      background-image: url('../img/ic3.png');
    }

    &_period {
      background-image: url('../img/ic4.png');
    }

    &_sms {
      background-image: url('../img/ic5.png');
    }
  }
}
