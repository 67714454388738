.step-description {
  text-align: center;
  height: 260px;

  @include respond(wide-phones){
    height: auto;
  }


  @include respond(wide-phones) {
    padding-bottom: 30px;
    padding-top: 30px;
  }

  &__headline {
    color: $text-color;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 15px;
    margin: 15px auto;
    max-width: 15.5em;
  }

  &__text {
    color: $text-color;
    font-size: 16px;
    margin: 0 auto;
    max-width: 23em;
  }

  &__col {
    display: inline-block;
    vertical-align: middle;
    width: 49%;
    text-align: right;

    img {
      max-width: 107%;
    }

    @include respond(wide-phones) {
      text-align: center;
      display: block;
      width: 100%;
    }
  }
  &__col:nth-of-type(2){
    text-align: left;
    @include respond(wide-phones) {text-align: center;}
  }
  &__img {
    max-width: 100%;

    @include respond(wide-phones) {
      display: none;
    }
  }

  &_border {
    &_both {
      /*border-bottom: 1px solid $step-description-border-color;
      border-top: 1px solid $step-description-border-color;*/
    }

    &_top {
      border-top: 1px solid $step-description-border-color;
    }

    &_bottom {
      border-bottom: 1px solid $step-description-border-color;
    }
  }
}
