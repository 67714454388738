.b-input {
  border: 1px solid $border-color;
  color: $text-color;
  font-family: $font-family-calibri;
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  padding: 17px 20px;
  width: 100%;

  &::-webkit-input-placeholder {
    color: $border-color;
  }

  &::-moz-placeholder {
    color: $border-color;
  }

  &:-moz-placeholder {
    color: $border-color;
  }

  &:-ms-input-placeholder {
    color: $border-color;
  }

  &_success {
    border-color: $success-color;
  }

  &_error {
    border-color: $error-color;
  }
}
