.exit-offer {
  border: 1px solid $text-color-exit-offer;
  text-align: center;
  padding: 30px;
  padding-bottom: 15px;
  max-width: 340px;
  margin: 0 auto;

  &__img {
    text-align: center;
    margin-bottom: 50px;

    img {
      display: inline-block;
      max-width: 100%;
    }
  }

  &__param {
    background-color: $text-color-invert;
    color: $text-color-exit-offer;
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 15px;

    &-name {
      color: $text-color;
      font-family: $font-family-museosans;
      font-weight: 300;
      font-size: 14px;
    }
  }


}