h1,
h2,
p,
input,
select {
  color: $text-color;
  font-size: 18px;

  @include respond(tablets) {
    font-size: 16px;
  }
}

body {
  font-family: $font-family-truth;
}

h1,
.h1 {
  font-size: 34px;
  font-weight: 700;

  &:first-child {
    margin-top: 0;
  }

  @include respond(tablets) {
    font-size: 26px;
  }

  @include respond(phones) {
    font-size: 24px;
  }
}

h2,
.h2 {
  font-size: 22px;
  font-weight: 700;

  &:first-child {
    margin-top: 0;
  }

  @include respond(tablets) {
    font-size: 18px;
  }
}

a {
  border-bottom: 1px solid transparent;
  color: $link-color;
  text-decoration: none;

  &:hover {
    border-bottom: 1px solid $link-border-color;
    color: $link-color;
    text-decoration: none;
  }
}

p {
  line-height: 1.6;
  margin: 30px 0;
}

ol {
  counter-reset: ol;
  padding: 0;
  padding-top: 21px;

  li {
    font-size: 16px;
    list-style: none;
    margin-bottom: 21px;

    &::before {
      background-color: $ol-num-color;
      border-radius: 50%;
      color: $text-color-invert;
      content: counter(ol);
      counter-increment: ol;
      display: inline-block;
      font-size: 12px;
      height: 23px;
      line-height: 23px;
      margin-right: 15px;
      text-align: center;
      width: 23px;
    }
  }
}

ul {
  padding: 0;
  padding-top: 21px;

  li {
    font-size: 16px;
    list-style-image: url('../img/ul_mark.png');
    margin-bottom: 21px;
    padding-left: 10px;
  }
}

.policy_ajax_block {
  max-width: 800px;
}


.item-image {
  float: left;
  margin-top: 16px;
  margin-right: 15px;
  margin-bottom: 2px;

  @include respond(phones) {
    float: none;
  }

  a {
    @include respond(phones) {
      display: block;
    }
  }

  img {
    @include respond(phones) {
      display: block;
      margin: 0 auto 5px;
    }
  }

}

.item-page {
  padding-bottom: 30px;
}

.items-leading {
  margin-bottom: 15px;

  p {
    text-align: justify;

    @include respond(phones) {
      clear: left;
    }
  }
}

[itemprop=articleBody] {
  p {
    text-align: justify;

    @include respond(phones) {
      clear: both;
    }
  }
}

.page-header {
  margin-top: 15px;
}

#reviews, #info_t {
  //background: #f6f6f6;
  width: 100%;
  padding-bottom: 78px;
  //padding-top: 78px;
  color: #939898;
}

#info_t {
  color: #303030;
  font-size: 14px;
}

#reviews h2, #info_t h2, #offers h2 {
  text-align: center;
  font-size: 30px;
  font-weight: normal;
  margin-bottom: 38px;
}

#info_t ul {
  border: 1px solid #fff;
}

#info_t li {
  float: left;
  width: 50%;
  border: 1px solid #f2f2f2;
  text-align: center;
  padding: 10px 10px 0;
  font-size: 14px;
  list-style: none;
  margin: 0;
  min-height: 120px;

  @include respond(wide-phones) {
    width: 100%;
    float: none;
  }
}

#info_t li h4 {
  font-weight: bold;
}

#info_t li p {
  font-size: 13px;
  margin: 0;
  padding-bottom: 15px;
}
