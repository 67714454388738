.bem_param {
  background: url('../img/money.svg') left center no-repeat;
  background-repeat: no-repeat;
  background-size: 19px;
  font-size: 14px;
  line-height: 26px;
  padding-left: 30px;

  &::before {
    /*background: $acent-color;
    border-radius: 50%;
    content: '';
    display: inline-block;
    height: 4px;
    margin-bottom: 4px;
    margin-right: 10px;
    width: 4px;*/
  }

  &_type {
    &_coin {
      background-image: url('../img/money.svg');
      background-repeat: no-repeat;
    }

    &_percent {
      background-image: url('../img/percent.svg');
      background-size: 16px;
      background-position: center left 2px;
      background-repeat: no-repeat;
    }

    &_age {
      background-image: url('../img/18plus.svg');
      background-repeat: no-repeat;
    }
  }
}
