.b-review-form {
  max-width: 360px;

  &__headline {
    text-align: center;
  }

  &__buttons {
    text-align: center;
    margin-bottom: 23px;
  }

  &__submit {
    background: $text-color;
    color: $text-color-invert;

    &:hover {
      background: #fff;
      color: $text-color;
    }
  }
}
