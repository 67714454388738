.blog-category-page {
  background: center center no-repeat;
  background-size: cover;
  margin-bottom: 71px;

  @include respond(wide-phones) {
    margin-bottom: 0;
  }

  &__overlay {
    background: rgba(0,0,0,0.5);
    padding-top: 102px;
    padding-bottom: 100px;

    @include respond(wide-phones) {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }

  &__headline {
    color: $text-color-invert;
    font-family: $font-family-avant-garderctt;
    font-size: 36px;
    font-weight: 700;
    text-align: center;

    @include respond(wide-phones) {
      margin-bottom: 0;
    }
  }
}