.button {
  background: #00a0d5 url(../img/arrow-right_circle.svg) center right 4% no-repeat;
  border-radius: 34px;
  color: #fff;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  line-height: 0.7;
  margin: 1px auto 0;
  padding: 21px 26px;
  padding-right: 66px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.2s;
}
