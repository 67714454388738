.bem_offer {
  border: 1px solid transparent;
  box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  margin-bottom: 21px;
  padding: 20px;
  position: relative;
  text-decoration: none;
  transition: 0.2s;

  @media (max-width: 997px) {
    display: inline-block;
    margin-bottom: 5%;
    margin-right: 5%;
    vertical-align: top;
    width: 47%;

    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  @media (max-width: 480px) {
    display: block;
    margin-right: 0;
    padding: 15px;
    width: auto;
  }

  &:link,
  &:visited {
    color: #616060;
    text-decoration: none;
  }

  &:hover {
    border-color: $acent-color;
  }

  &:hover & {
    &__note {
      background: #f19f00;
    }

    &__get {
      .button {
        background-color: #f19f00;
      }
    }
  }

  &__note {
    background: -webkit-linear-gradient(0deg, #00a0d5 23%, #00a0d5 66%, #00a0d5 100%);
    background: -moz-linear-gradient(0deg, #00a0d5 23%, #00a0d5 66%, #00a0d5 100%);
    background: -o-linear-gradient(0deg, #00a0d5 23%, #00a0d5 66%, #00a0d5 100%);
    background: -ms-linear-gradient(0deg, #00a0d5 23%, #00a0d5 66%, #00a0d5 100%);
    background: #00a0d5;
    color: #fff;
    font-size: 14px;
    left: 9px;
    line-height: 1;
    padding: 10px 20px 7px;
    position: absolute;
    text-transform: uppercase;
    top: 0;
    transition: 0.2s;
  }

  &__info,
  &__image,
  &__text,
  &__get {
    font-size: 14px;
    vertical-align: top;
    width: 25%;

    @media (max-width: 769px) {
      width: auto;
    }
  }

  &__info {
    margin: 0 auto;
    vertical-align: middle;

    @media (max-width: 769px) {
      margin-bottom: 24px;
    }

    @media (max-width: 480px) {
      margin-bottom: 10px;
    }
  }

  &__text {
    font-size: 14px;
    margin-top: 9px;
    //display: flex;
    //align-items: center;
    span {
      color: #00a0d5;
    }

    b {
      color: $acent-color;
    }

    @media (max-width: 769px) {
      margin-bottom: 20px;
      text-align: center;
    }

    @media (max-width: 480px) {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }

  &__image {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0;
    text-align: center;
    vertical-align: middle;

    @media (max-width: 997px) {
      display: block;
      margin-bottom: 15px;
      margin-right: 0;
      width: auto;
    }

    @media (max-width: 480px) {
      margin-bottom: 10px;
    }
  }

  &__get {
    align-items: center;
    display: flex;
    text-align: right;
    vertical-align: middle;

    @media (max-width: 1025px) {
      width: 19%;
    }

    @media (max-width: 997px) {
      display: block;
      text-align: center;
      width: auto;
    }
  }
}
