.ui-menu .ui-menu-item {
  background: none;
  padding-left: 0;
}

.ui-selectmenu-button.ui-button {
  padding: 15px 16px;
  width: 100%;
}

.ui-corner-all {
  border-radius: 0;
}

.ui-button {
  background: $text-color-invert;

  &:active {
    background: $link-color;
  }
}

.ui-state-active,
.ui-widget-content .ui-state-active {
  background: $select-item-active-bg;
  border-color: $select-item-active-bg;
  color: $select-item-active-text-color;
}

.ui-menu .ui-menu-item {
  color: $select-item-active-text-color;
  padding: 4px;
}

.ui-menu .ui-menu-item-wrapper {
  padding: 12px 11px;
}

.has-error {
  .ui-button {
    border-color: $error-color;
  }
}

.has-success {
  .ui-button {
    border-color: $success-color;
  }
}
