.exit-popup {
  display: none;
  background: $text-color-invert url(../img/exit-popup__megafon.svg) bottom 10px left 10px no-repeat;
  background-size: 183px 170px;
  padding: 36px;
  max-width: 828px;

  @include respond(wide-phones) {
    background: $text-color-invert;
  }

  &__left-col {
    display: inline-block;
    padding-bottom: 141px;
    vertical-align: top;
    width: 58%;

    @include respond(wide-phones) {
      width: 100%;
      padding-bottom: 20px;
    }
  }

  &__right-col {
    display: inline-block;
    vertical-align: top;
    width: 41%;

    @include respond(wide-phones) {
      width: 100%;
    }
  }

  &__headline {
    color: $text-color;
    font-family: $font-family-museosans;
    font-size: 30px;
    font-weight: 300;
    text-align: center;
    margin-bottom: 15px;
    margin-top: 30px;
  }

  &__text {
    color: $text-color;
    font-family: $font-family-museosans;
    font-size: 24px;
    font-weight: 300;
    text-align: center;
    margin-bottom: 20px;
  }

  &__buttons {
    text-align: center;
  }
}