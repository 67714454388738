.baloon {
  &__img {
    padding-top: 49px;

    @include respond(phones) {
      display: none;
    }
  }

  .col-xs-8 {
    @include respond(phones) {
      width: 100%;
    }
  }
}
