.b-advantages {
 /* background: $bg-advantages;*/
  padding-bottom: 30px;
  padding-top: 0px;
  text-align: center;

  &__item {
    display: inline-block;
    vertical-align: top;
    width: 19%;

    @include respond(small-desktops) {
      margin-bottom: 15px;
      width: 32%;
    }

    @include respond(wide-phones) {
      width: 49%;
    }

    @include respond(phones) {
      width: 100%;
    }
  }
}
