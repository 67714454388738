.blog-category {
  background: #fff top center no-repeat;
  background-size: 60%;
  color: $text-color;
  font-family: $font-family-avant-garderctt;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  padding: 232px 15px 16px;
  transition: transform 0.2s;  

  &:hover {
    @media (min-width: 769px) {
      border: 0;
      transform: scale(1.1, 1.1);
    }
  }

  @include respond(phones) {
    padding-top: 177px;
    background-position: top 5% center;
  }

  @include respond(small-desktops) {
    padding-top: 171px;
  }

  @include respond(tablets) {
    background-position: top center;
    padding-top: 137px;
  }

  @media (max-width: 350px) {
    padding-top: 249px;
  }
}