.wizard {
  &__step {
    &_hidden {
      display: none;
    }
  }

  &__btn-submit {
    &_hidden {
      display: none;
    }
  }

  &__btn-prev {
    &_hidden {
      display: none;
    }
  }

  &__btn-next {
    &_hidden {
      display: none;
    }
  }
}
