.b-reviews {
  background:  url('/img/step-description__0.jpg')  center no-repeat;
  background-size: cover;
  padding:0;
  margin-bottom: 100px;
  color: $text-color-invert;

  @include respond(tablets) {
    /*background: none;*/
  }

  &__headline {
    background: url('../img/reviews__header.svg') center center no-repeat;
    background-size: 390px 167px;
    color: $text-color-invert;
    font-size: 47px;
    font-weight: 400;
    padding: 20px 0;
    text-align: center;

    @include respond(phones) {
      background: none;
    }
  }

  &__col {
    display: inline-block;
    vertical-align: middle;
    width: 49%;

    &:first-child {
      padding-right: 30px;
      padding-left: 30px;
      margin-bottom: -16px;
      margin-top: -16px;
      background: $bg-color-darkblue;
      min-height: 500px;

      @include respond(wide-phones) {
        padding-right: 0;
      }
    }

    @include respond(tablets) {
      display: block;
      width: 100%;
    }
  }

  &__next-slide,
  &__prev-slide {
    border-bottom: 1px dotted $text-color-invert;
    color: $text-color-invert;
    cursor: pointer;
    display: inline-block;
    margin-left: 20px;
    margin-top: 40px;
    margin-bottom: 25px;

    &:hover {
      border-bottom: 1px dotted transparent;
    }
  }

  &__form {
    margin-left: 106px;
    margin-bottom: 20px;

    @include respond(tablets) {
      display: block;
      margin: 0 auto;
    }
  }
}
.b-reviews-mask {
  padding: 0;
  background: rgba(#004c64, 0.7);
}

.b-block.b-reviews-mask .col-md-12 {
  @include respond(phones) {
    padding: 0;
  }
}

#review_thks{
  display: none;
  padding: 30px;
}