.b-footer {
  background: $bg-color-darkblue;
  padding-bottom: 60px;
  padding-top: 52px;
  position: relative;

  @include respond(phones) {
    padding-top: 30px;
  }

  &__get-btn {
    margin-bottom: 15px;
  }

  a {
    border-bottom: 0;
    color: #fff;
    cursor: pointer;

    &:hover {
      border-bottom: 0;
    }
  }

  .col-xs-6 {
    @include respond(phones) {
      width: 100%;
    }
  }

  &::after {
    background: rgba($text-color-invert, 0.7);
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  @include respond(phones) {
    padding-bottom: 0;
  }

  &__copy {
    color: $text-color-invert;
    font-family: $font-family-arial;
    font-size: 14px;

    span {
      @include respond-to(wide-phones) {
        line-height: 40px;
      }
    }

    @include respond-to(wide-phones) {
    }
  }

  &__text {
    border-top: 2px solid rgba($text-color-invert, 0.3);
    color: $text-color-invert;
    font-size: 13px;
    line-height: 18px;
    margin-top: 29px;
    padding-top: 29px;

    @include respond(wide-phones) {
      margin-top: 15px;
      padding-top: 15px;
    }
  }

  &__text-row {
    @include respond(phones) {
      background: $bg-color-dark;
    }
  }
}

a.for-webmasters {
  margin: 15px 0 0 0;
  border: 2px solid #fff;
  border-bottom: 2px solid #fff;
  padding: 5px;
  display: inline-block;
  &:hover {
    border: 2px solid #fff;
    border-bottom: 2px solid #fff;
    background: #fff;
    color: #333;
  }
}