.blog-offers-list {
  &__item {
    display: block;
    vertical-align: top;
    max-width: 260px;
    margin: 0 auto;
    margin-bottom: 21px;
  }

  &__more {
    text-align: center;
    margin-bottom: 30px;
  }

  @include respond(wide-phones) {
    display: none;
  }
}