.how-it-work {
  border: 1px solid $acent-color;
  text-align: center;

  &__headline {
    background: $text-color-invert;
    display: inline-block;
    line-height: 1;
    margin: 0;
    padding: 0 15px;
    transform: translateY(-50%);
  }

  &__item {
    display: inline-block;
    position: relative;
    width: 32%;

    @include respond(phones) {
      padding: 31px 0;
      width: 100%;
    }

    &::after {
      content: url('../img/how-it-work__arrow.png');
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(50%, -50%);

      @include respond(phones) {
        bottom: 0;
        content: url('../img/how-it-work__arrow_down.png');
        left: 50%;
        right: auto;
        top: auto;
        transform: translate(-50%, 50%);
      }
    }

    &:last-child::after {
      content: '';
    }
  }

  &__content {
    padding-bottom: 46px;
    padding-top: 46px;

    @include respond(phones) {
      padding: 0 15px;
    }
  }
}
