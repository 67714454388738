.form-step-1 {
  padding-top: 100px;

  &__headline {
    font-size: 34px;

    span {
      color: $acent-color;
    }
  }
}
