.b-mobile-menu {
  display: none;

  @include respond(wide-phones) {
    display: block;
  }

  &::after {
    content: '';
    display: block;
    clear: both;
  }

  @include respond(wide-phones) {
    background: #fff;
    position: fixed;
    left: 0;
    top: -70px;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    padding: 15px;
    z-index: 0;
    clear: both;
    margin: 0;
    transition: left 0.2s;
    padding-bottom: 70px;
    transform: translateY(70px);
  }

  &_open {
  }

  &__close {
    display: none;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: #fff;
    font-family:$font-family-museosans;
    padding: 9px 16px;
    position: absolute;
    line-height: 1;
    top: 10px;
    right: 10px;

    @include respond(wide-phones) {
      display: block;
    }
  }
}

.b-mobile-menu__body {
  overflow: hidden;
  position: fixed;
}

.b-mobile-menu__wrapper {
  background: $text-color-invert;
  position: relative;
  z-index: 1;
  transition: transform 0.2s;
}

.b-mobile-menu__wrapper_slide-out {
  transform: translateX(-80%);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);

  .navbar-toggle {
    padding-right: 3px;

    .icon-bar {
      transform-origin: left center;
      transform: rotate(0deg);
    }
    .icon-bar:nth-child(3n) {
      width: 0;
      overflow: hidden;
      opacity: 0;
    }
    .icon-bar:nth-child(2n) {
      transform: rotate(45deg);
    }
    .icon-bar:nth-child(4n) {
      transform: rotate(-45deg);
    }
  }
}