.blog-main-page {
  background: url('../img/main-page__bg-start.png') center center no-repeat;
  background-size: cover;
  margin-bottom: 70px;
  padding-top: 92px;
  padding-bottom: 84px;

  @include respond(wide-phones) {
    padding-top: 30px;
    padding-bottom: 30px;
    margin-bottom: 10px;
  }

  &__headline {
    color: #fff;
    text-align: center;
    margin: 0;
  }
}