.about-us {
  .col-xs-8,
  .col-xs-4 {
    @include respond(phones) {
      position: static;
      width: 100%;
    }
  }

  &__images {
    @include respond(phones) {
      text-align: center;
    }

    img {
      @include respond(phones) {
        display: inline-block;
      }
    }
  }

  &__buttons {
    padding-bottom: 15px;

    @include respond(phones) {
      text-align: center;
    }
  }
}
