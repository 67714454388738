.blog-offers-anchor {
  color: $text-color-invert;
  background: $acent-color;
  display: none;
  font-weight: 600;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 10px;
  z-index: 1;

  &:hover {
    background: $acent-color;
  }

  &_active {
    @include respond(wide-phones) {
      display: block;
      animation: offersAnchorShow 0.5s;
    }
  }
}

@keyframes offersAnchorShow {
  from {
    display: block;
    opacity: 0;
  }
  to {
    display: block;
    opacity: 1;
  }
}