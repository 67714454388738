.b-review {
  font-size: 14px;
  padding: 0 20px;
  position: relative;


 /* &::before {
    content: '\201c';
    font-family: $font-family-kundera;
    font-size: 131px;
    left: 0;
    line-height: 1;
    position: absolute;
    top: 0;
  }

  &::after {
    bottom: 0;
    content: '\201e';
    font-family: $font-family-kundera;
    font-size: 131px;
    line-height: 1;
    position: absolute;
    right: 0;
  }*/

  p {
    font-size: 14px;
    margin: 0;
    padding-top: 18px;
    color: $text-color-invert;
  }

  &__author {
    margin-top: 26px;

  }
}
