.blog-ol,
[itemprop=articleBody] ol {
  counter-reset: blogOl;
  li {
    list-style: none;
    margin-bottom: 15px;

    &::before {
      background-color: $bg-color-blog-ol;
      border: 1px solid $text-color-invert;
      border-radius: 50%;
      box-shadow: 0 0 0 2px $bg-color-blog-ol;
      color: $text-color-invert;
      content: counter(blogOl);
      counter-increment: blogOl;
      display: inline-block;
      font-size: 14px;
      font-family: $font-family-museosans;
      font-weight: 700;
      text-align: center;
      line-height: 20px;
      margin-right: 9px;
      width: 20px;
      height: 20px;
    }
  }
}