.spoiler {
  &__btn {
    display: none;

    @include respond(phones) {
      background: lighten($acent-color, 50%);
      color: $acent-color;
      cursor: pointer;
      display: block;
      font-size: 16px;
      line-height: 49px;
      text-align: center;
    }

    span {
      border-bottom: 2px dotted;
    }
  }

  &__content {
    @include respond(phones) {
      border: 2px solid lighten($acent-color, 50%);
      display: block;
      padding: 5px;
    }
  }

  &_collapse & {
    &__content {
      @include respond(phones) {
        display: none;
      }
    }
  }
}
