.blog-post {
  background: center center no-repeat;
  background-size: cover;
  padding-top: 100px;
  padding-bottom: 100px;
  margin-bottom: 76px;

  @include respond(wide-phones) {
    display: none;
  }
}