.blog-quote,
[itemprop=articleBody] blockquote {
  background-color: $bg-color-blockqoute;
  border-left: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 30px 58px;
  position: relative;
  margin-top: 25px;
  margin-bottom: 25px;

  @include respond(phones) {
    padding: 30px 15px;
  }

  &::before {
    background: $text-color-invert url(../img/blockquoute.svg) center center no-repeat;
    background-size: 83%;
    border-radius: 50%;
    content: '';
    display: inline-block;
    position: absolute;
    left: 50%;
    line-height: 0;
    top: -26px;
    margin-bottom: -25px;
    margin-top: 0;
    margin-left: -25px;
    padding: 25px;
    width: 30px;
    height: 30px;
  }

  p {
    color: $text-color;
    font-family: $font-family-museosans;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.2;
    margin: 0;
  }

  p + p {
    margin-top: 15px;
  }
}