.blog-post-preview {
  &__headline {
    color: $acent-color;
    font-size: 18px;
    font-family: $font-family-avant-garderctt;
    font-weight: 400;
    margin: 0;
  }

  &__link {
    color: $acent-color;
  }

  &__text {
    color: $text-color;
    font-family: $font-family-avant-garderctt;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    margin-top: 21px;
  }

  &__meta {
    margin-top: 17px;
  }

  &__views {
    color: $text-color-blog-meta;
    font-family: $font-family-avant-garderctt;
    font-weight: 400;
    font-size: 12px;
    background: url('../img/blog-post-preview__views.svg') left center no-repeat;
    background-size: 16px 11px;
    padding-left: 21px;
  }
}