.blog-offer {
  box-shadow: 0 1px 7px rgba(0, 0, 0, 0.1);

  &__img {
    text-align: center;
    padding-top: 30px;
    padding-bottom: 25px;

    img {
      max-width: 100%;
    }
  }

  &__params {
    background-color: $bg-color-blog-offer;
    color: $text-color-blog-offer;
    font-size: 14px;
    font-family: $font-family-museosans;
    font-weight: 500;
    padding-top: 11px;
    padding-bottom: 10px;
    text-align: center;

    p {
      color: $text-color-blog-offer;
      font-size: 14px;
      font-family: $font-family-museosans;
      font-weight: 500;
      margin: 5px 0;
    }
  }

  &__text {
    color: $text-color-blog-offer-main;
    font-family: $font-family-museosans;
    font-weight: 300;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;

    span,
    a,
    strong {
      color: $text-color-blog-offer-main-alt;
    }
  }

  &__btn {
    padding: 17px;
    padding-top: 0;

    .btn {
      min-width: auto;
      width: 100%;

      @include respond-to(tablets) {
        font-size: 12px;
      }
    }
  }
}