.blog-recent-post {
  padding-top: 0;
  padding-bottom: 80px;

  @include respond(wide-phones) {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  &__img {
    @include respond(wide-phones) {
      display: block;
      margin-bottom: 15px;
      text-align: center;
    }

    img {
      @include respond(wide-phones) {
        display: inline-block;
      }
    }
  }

  &__headline {
    color: $text-color;
    font-family: $font-family-avant-garderctt;
    font-size: 34px;
    font-weight: 400;
    margin: 0;
    margin-bottom: 17px;
  }

  &__item {
    margin-bottom: 20px;
  }
}

* + .blog-recent-post {
  padding-top: 76px;

  @include respond(wide-phones) {
    padding-top: 30px;
  }
}