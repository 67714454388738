.b-block {
  padding: 89px 0;

  @include respond-to(wide-phones) {
    padding: 30px 0;
  }

  &__headline {
    font-size: 34px;
    font-weight: 400;
    margin-bottom: 30px;
    text-align: center;
  }

  @include respond(phones) {
    padding: 30px 0;
  }
}
