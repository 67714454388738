.registration-form {
  margin: 0 auto;
  padding-bottom: 60px;

  &_width_full & {
    &__content {
      padding: 0;
    }
  }

  &__steps {
    margin-bottom: 20px;
  }

  &__headline {
    font-size: 34px;
    font-weight: 400;
    margin-bottom: 32px;
    margin-top: 10px;

    @include respond(phones) {
      font-size: 23px;
    }
  }

  &__content {
    margin-bottom: 39px;
    padding: 0 45px;

    @include respond(phones) {
      padding: 0;
    }
  }

  &__step-name {
    color: $b-step-color;
    display: none;
    font-family: $font-family-truth;
    font-size: 16px;
    text-align: center;

    @include respond(phones) {
      display: block;
    }
  }
}
