.vendor-logo {
  display: block;
  margin: 0 auto;
  margin-bottom: 15px;

  @include respond(wide-phones) {
    display: inline-block;
    margin: 0 15px 15px 0;
  }
}
