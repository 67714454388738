.bem_offers {
  padding-bottom: 60px;
  padding-top: 30px;
  @include respond(phones) {
    padding-top: 5px;
  }


  &__text {
    padding: 0;
    font-size: 16px;

    @include respond(phones) {
      font-size: 12px;
    }

    @media (max-width: 769px) {
      padding: 0;
    }

    @media (max-width: 480px) {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &__headline {
    margin-bottom: 32px;
    text-align: center;
    font-size: 24px;
    line-height: 1.5;
    font-weight: 400;
    border: 1px solid $acent-color;
    padding: 20px;

    @include respond(phones) {
      line-height: 1.2;
      font-size: 20px;
      padding: 8px 15px;
      margin-bottom: 0;
    }

    .color_span{
      color: $acent-color;
      font-size: 24px;
      @include respond(phones) {
        line-height: 1.2;
        font-size: 20px;
      }
    }
    span {
     /* color: $acent-color;*/
      font-size: 16px ;

      @include respond(phones) {
        font-size: 12px;
        line-height: 1.3em;
        display: block;
      }
    }

    strong {
      color: $acent-color;
    }
  }

  &__headline_larger {
    font-size: 34px;

    @include respond(phones) {
      font-size: 28px;
    }

    @include respond(landscape-phones) {
      font-size: 30px;
    }

    span {
      &.bem_offers__note {
        display: inline-block;
        font-size: 50%;
      }
    }
  }

  &__subheader {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 14px;
    margin-top: 61px;
    text-align: center;
  }
}
