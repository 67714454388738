.work-step {
  background: top center no-repeat;
  background-size: 129px;
  font-size: 12px;
  padding-top: 158px;
  text-transform: uppercase;

  @include respond(phones) {
    font-size: 16px;
  }

  &_type {
    &_ok {
      background-image: url('../img/work-step__ok.svg');
    }

    &_registration {
      background-image: url('../img/work-step__registration.svg');
    }

    &_take-money {
      background-image: url('../img/work-step__take-money.svg');
    }
  }
}
