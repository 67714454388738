.blog-categories-tile {
  display: flex;
  flex-wrap: wrap;

  @include respond(wide-phones) {
    padding-top: 15px;
  }

  &__item {
    display: inline-block;
    margin-right: 1%;
    margin-bottom: 1%;
    width: 32%;

    &:nth-child(3n) {
      margin-right: 0;

      @include respond(phones) {
        margin-right: 1%;
      }

      @media (max-width: 350px) {
        margin-right: 0;
      }
    }

    &:nth-child(2n) {
      @include respond(phones) {
        margin-right: 0;
      }
    }


    @include respond(phones) {
      width: 49%;
    }

    @media (max-width: 350px) {
      width: 100%;
      margin-right: 0;
    }
  }

}